@font-face {
    font-family: "Untitled-Sans-Regular";
    src: url('../assets/fonts/untitled-sans-web-regular.eot');
    src: url('../assets/fonts/untitled-sans-web-regular.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/untitled-sans-web-regular.woff2') format('woff2'),
         url('../assets/fonts/untitled-sans-web-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Untitled-Sans-Medium";
    src: url('../assets/fonts/untitled-sans-web-medium.eot');
    src: url('../assets/fonts/untitled-sans-web-medium.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/untitled-sans-web-medium.woff2') format('woff2'),
         url('../assets/fonts/untitled-sans-web-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Untitled-Sans-Bold";
    src: url('../assets/fonts/untitled-sans-web-bold.eot');
    src: url('../assets/fonts/untitled-sans-web-bold.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/untitled-sans-web-bold.woff2') format('woff2'),
         url('../assets/fonts/untitled-sans-web-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

