:root {
    --easing: cubic-bezier(0.5, 0.7, 0.4, 1);
    --easing-overshoot: cubic-bezier(0.365, 0.050, 0.780, 1.650);
    --bg-color: #14100c;
    --card-color: #0e0b09;
    --alt-color: #e45151;
}

::-moz-selection {
    color: var(--bg-color); 
    background: var(--alt-color);
}
  
::selection {
    color: var(--bg-color); 
    background: var(--alt-color);
}

body {
    font-family: "Untitled-Sans-Regular", sans-serif;
    line-height: 1.2;
    color: var(--alt-color);
    background-image: url('../assets/img/bg-intro-2-blend-hard-light.jpg');
    background-blend-mode: hard-light;
    background-attachment: fixed;
    background-size: cover;
    background-position: top left;
    background-repeat: no-repeat;
    background-color: var(--bg-color);
    transition: 300ms background-color var(--easing);
    z-index: -3;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;

    @include large {
        background-image: none;
    }
}

.background-color {
    background-color: var(--bg-color);
    z-index: -1;
    height: 100vh;
    width: 100%;
    position: fixed;
}

.logo {
    visibility: hidden;
    position: fixed;
    width: 52px;
    height: 52px;
    top: 16px;
    left: 16px;
    border: 0px solid var(--alt-color);
    border-radius: 6px;
    fill: var(--alt-color);
    cursor: pointer;
    z-index: 10;
    transition: 2s fill var(--easing),
                300ms scale;

    &:focus {
        opacity: 0.6;
        outline: 0;
    }

    &:active {
        background-color: var(--alt-color);
        color: var(--bg-color);
        outline: 0;
    }

    @include large {
        background-color: var(--bg-color);
    }

    @include medium {
        left: 8px;
        top: 8px;
        width: 32px;
        height: 32px;
    }

    .pppp-logo {
        width: 52px; // only set width OR height for SVGs, as we preserve aspectRatio in the SVG itself
        transition: 300ms transform var(--easing);

        @include medium {
            width: 32px;
        }

        &:focus {
            transform: scale(2);
        }
    }
}

.wrapper {
    padding: 28px 0px 16px 0px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1680px;

    @include medium {
        padding: 14px 0px 16px 48px;
    }
}

a.further {
    border-bottom: 2px solid var(--alt-color);
    border-radius: 2px;
    padding: 2px;
    margin-top: 0.4rem;
    transition: 2s border-bottom var(--easing);

    &:focus, &:hover {
        outline: 0;
        background-color: var(--alt-color);
        color: var(--bg-color);
    }

    &:active {
        opacity: 0.8;
    }
}

header {
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 3;
    width: 100%;
    padding-top: 28px;
    padding-left: 84px;

    @include large {
        background-color: var(--bg-color);    
        justify-content: flex-start;
        margin-left: 0px;
        padding-top: 0px;
        height: 84px;
    }

    @include medium {
        padding-left: 48px;
        height: 48px;
    } 

    h1 {
        font-size: 1.4rem;
        position: sticky;
        color: inherit;
        transition: 2s color var(--easing);

        @include medium {
            font-size: 1rem;
        }
    }

    .rotated {
        margin-right: 8px;
        transition: 2s color var(--easing);
        color: inherit;
        position: fixed;
        top: 50vh;
        left: -8.6rem;
        transform: rotate(270deg);

        @include medium {
            display: block;
            left: -160px;
        }
    }
}

footer {
    .colored-songs-container {
        visibility: hidden;
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: var(--alt-color);
        width: 100%;
        padding: 0px 16px;
        font-size: 1rem;
        color: var(--bg-color);
        transition: background-color 2s var(--easing);
        z-index: 3;

        @include large {
            display: none;
        }

        #color-bar {
            background-color: var(--bg-color);
            height: 4px;
            width: 44px;
            border-radius: 2px;
            transform: translate(0px);
            z-index: 2;
            position: fixed;
            bottom: 0px;
            left: 10px;
            transition: left 200ms ease-in-out,
                        background-color 300ms var(--easing);

            @include medium {
                display: none;
            }
        }
    }
}

.description {
    padding: 12px;

    @include very-large {
        padding: 16px;
    }
}

.colored-songs {
    font-family: "Untitled-Sans-Medium";
    margin-left: 4px;
    padding: 12px;
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 2px;

    @include very-large {
        padding: 8px;
    }

    &:focus, &:hover {
        outline: 0px;
        opacity: 0.4;
    }

    &:active {
        background-color: var(--bg-color);
        color: var(--alt-color);
    }
}

.colored-songs-menu {
    display: none;
    background-color: transparent;
    padding-right: 30px;
    padding-bottom: 10rem;
    visibility: hidden;
    position: fixed;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: -100vh;
    width: 100vw;
    height: 100vh;
    font-size: 1rem;
    text-align: center;
    border-radius: 2px;
    background-color: var(--bg-color);
    color: var(--alt-color);
    z-index: 4;
    opacity: 0.3;
    transition: 600ms margin-top var(--easing),
                600ms opacity,
                300ms color var(--easing),
                600ms background-color var(--easing);

    p {
        background-color: var(--bg-color);
        position: absolute;
        // opacity: 0.7;
        margin-bottom: 2.6rem;
        font-size: 1rem;
        margin-right: 8px;
        top: 50vh;
        left: -118px;
        padding: 2px 8px;
        border-radius: 16px;
        transform: rotate(270deg);
        transition: 2s color var(--easing);
    }

    a {
        padding: 12px;
        font-family: "Untitled-Sans-Medium";
        margin-bottom: 0.4rem;
        border: 2px solid var(--bg-color);
        border-radius: 2px;
        transition: 2s border-color var(--easing),
                    2s color var(--easing);

        &:focus, &:hover {
            outline: 0px;
            border: 2px solid var(--alt-color);
        }

        &:active {
            background-color: var(--alt-color);
            color: var(--bg-color);
        }

        @include large {
            font-size: 1.4rem;
            font-family: "Untitled-Sans-Medium";
        }
    }

    @include large {
        display: flex;
        margin-left: 84px; // so that main content can be seen
        padding-right: 114px; // translate it back
    }

    @include medium {
        margin-left: 48px; // so that main content can be seen
        padding-right: 78px; // translate it back
    }
}

.opened {
    visibility: hidden;
    background-color: var(--bg-color);
    margin-top: 0vh;
    opacity: 1;
}

#intro {
    visibility: hidden;
    display: flex;
    min-height: 100vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: inherit;
    transition: 2s color var(--easing);

    @include medium {
        flex-direction: row;
        padding-left: unset;
        padding-right: 48px;
        justify-content: space-between;
    }

    h2 {
        font-size: 3rem;
        padding-bottom: 1.2rem;

        @include medium {
            width: 40%;
            font-size: 1.8rem;
            padding-bottom: 0px;
            margin-bottom: 128px;
            text-align: right;
        }
    }

    p {
        font-size: 1.8rem;
        margin-bottom: 0.6rem;

        @include medium {
            padding-left: 8px;
            width: 32%;
            margin-bottom: 128px;
        }
    }

    .scroll-arrow {
        font-size: 4rem;
        transform: rotate(90deg);
        position: fixed;
        left: 50vw;
        bottom: 86px;
        color: inherit;
        transition: 2s color var(--easing);

        @include large {
            position: absolute;
        }
    }
}

#about-me {
    display: flex;
    min-height: 100vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: inherit;
    transition: 2s color var(--easing);

    @include medium {
        padding-left: unset;
        padding-right: 16px;
    }

    p {
        font-size: 2.5rem;
        width: 76%;
        margin-bottom: 2rem;

        @include medium {
            width: unset;
            font-size: 1.5rem;
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}

#work {
    min-height: 100vh;
    color: inherit;
    transition: 2s color var(--easing);

    @include main-padding;

    @include large {
        padding-right: 16px;
    }

    @include medium {
        padding-left: 0px;
        padding-right: 36px;
        width: 100%;
    }

    .work-item {
        border-radius: 4px;
        padding: 4rem;
        background: linear-gradient(to top,var(--bg-color), var(--card-color));
        margin-bottom: 10rem;
        align-items: center;

        @include large {
            padding: 2rem;
        }

        @include medium {
            padding: 1rem;
        }

        h3 {
            min-width: 40%;
            padding: 4px 4px 4px 0px;

            @include medium {
                text-align: left;
                font-size: 1.6rem;
            } 
        }

        p {
            margin-bottom: 3rem;

            @include medium {
                margin-bottom: 1rem;
            }
        }

        .work-item-top {
            width: 100%;
            margin-bottom: 3rem;

            @include medium {
                margin-bottom: 1.4rem;
            }
        }

        .work-item-main {
            display: flex;
            flex-direction: row;
            padding-top: 64px;

            @include medium {
                flex-direction: column-reverse;
                padding-top: 0px;
            }

            .work-item-left {
                width: 40%;
                margin-right: 44px;
                overflow: hidden;

                @include large {
                    width: 45%;
                }

                @include medium {
                    width: 100%;
                    padding-top: 2rem;
                }
    
                h4 {
                    font-family:'Untitled-Sans-Medium';
                }

                p {
                    margin-bottom: 1rem;
                }
            }
    
            .work-item-right {
                width: 60%;
                font-size: 1.4rem;

                @include large {
                    width: 55%;
                }

                @include medium {
                    width: 100%;
                    font-size: 1rem;
                }

                p {
                    padding: 4px 4px 4px 0px;
                }
    
                img {
                    width:100%;
                    height: auto;
                    max-width: 86%;
                    box-shadow: 7px 7px 18px 0px #0c0908;
                }
            }
        }

        .work-item-footer {
            width: 100%;
        }
    }
}

#services {
    min-height: 100vh;
    color: inherit;
    transition: 2s color var(--easing);

    @include small {
        flex-direction: column;
        align-items: stretch;
    }

    div {
        display: flex;
        flex-direction: row;
        text-align: center;

        @include main-padding;

        @include large {
            flex-direction: column;
            justify-content: flex-start;
            padding-left: 96px;
            padding-right: 96px;
        }

        @include medium {
            padding-left: 0px;
            padding-right: 48px;
        }
    }

    .service-item {
        padding: 2rem;
        border-radius: 4px;
        margin: 1.4rem;
        text-align: left;
        flex-basis: 100%;
        min-width: 0; // serves as flex-box overflow hack

        .service-number {
            font-family: "Untitled-Sans-Regular";
            font-size: 2rem;
            padding-left: 0px;
            padding-right: 0px;

            @include large {
                font-size: 1.6rem;
            }

            @include medium {
                text-align: left;
            }
        }

        h3 {
            margin-bottom: 2rem;

            @include large {
                text-align: center;
                font-size: 1.6rem;
                margin-bottom: 1rem;
            }

            @include medium {
                text-align: left;
            }
        }

        @include small {
            width: 100%;
            margin-left: 0px;
            margin-right: 0px;
        }

        @include medium {
            padding: 16px;
            text-align: left;
        }
    }
}

#contact {
    @include main-padding;
    padding-bottom: 10rem;
    display: flex;
    min-height: 100vh;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: inherit;
    transition: 2s color var(--easing);

    @include medium {
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 0px;
        padding-right: 48px;
    }

    p {
        font-size: 2rem;
        margin-bottom: 2rem;

        @include large {
            font-size: 1.4rem;
        }
    }

    .contact-left {
        @include large {
            margin-top: 10rem;
        }
    }

    .contact-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 10vh;

        .contact-buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @include large {
                padding-left: 32px;
                flex-direction: column;
                font-size: 1.4rem;
            }

            a.get-in-touch {
                display: block;
                padding: 12px;
                font-size: 2rem;
                border-radius: 2px;
                margin-right: 28px;
                margin-bottom: 0.4rem;
                border: 2px solid transparent;
                color: var(--var-color);
                font-family: "Untitled-Sans-Medium";
                transition: background-color 300ms ease-out;

                @include medium {
                    font-size: 1.4rem;
                }
            
                &:focus, &:hover {
                    outline: 0px;
                    border: 2px solid var(--alt-color);
                }
        
                &:active {
                    background-color: var(--alt-color);
                    color: var(--bg-color);
                }
            }
        }
    }
}

#copyright {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
    transition: 2s color var(--easing);

    .small {
        font-size: 0.8rem;
    }

    @include medium {
        padding-left: 48px;
        padding-right: 98px;
        text-align: center;
    }
}

h2, h3 {
    font-family: 'Untitled-Sans-Medium', sans-serif;
    font-size: 2rem;
}

h4 {
    margin-bottom: 0.2rem;
}

.pre-headline {
    margin-bottom: 0.2rem;
 
    @include medium {
        text-align: left;
        margin-bottom: 0.4rem;
    }
}

.section-headline {
    margin-top: 10rem;
    margin-bottom: 10rem;
    margin-left: 86px;
    padding-right: 86px;
    text-align: right;
    font-size: 1.4rem;
    font-family: "Untitled-Sans-Regular";
    transition: 2s color var(--easing);

    @include large {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: sticky;
        top: 0;
        z-index: 3;
        background-color: var(--bg-color);
        font-size: 1.4rem;
        padding-right: 32px;
        margin-bottom: 4rem;
        height: 84px;
    }

    @include medium {
        font-size: 1rem;
        margin-left: 0px;
        padding-right: 48px;
        height: 48px;
    }
}

.bold {
    font-family: "Untitled-Sans-Bold";
}

.medium {
    font-family: "Untitled-Sans-Medium";
}

.logo-privacy {
    visibility: visible;
}

.privacy {
    padding: 2rem 14rem 4rem 14rem;

    @include medium { padding: 4rem 48px }

    h1 {
        font-size: 2rem;
        font-family: "Untitled-Sans-Medium";
        margin-bottom: 1rem;
    }

    h2 {
        margin-top: 2.6rem;
        font-size: 1.4rem;
        margin-bottom: 0.4rem;
    }

    h3 {
        margin-top: 1rem;
        font-family: "Untitled-Sans-Bold";
        font-size: 1rem;
        margin-bottom: 0.4rem;
    }

    p {
        margin-bottom: 2rem;
    }
}