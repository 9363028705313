/* Based on Elad Shechter's browser reset */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
a, a:visited {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin: 0;
}
ul, ol, dd {
  margin: 0;
  padding: 0;
  list-style: none;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}
p {
  margin: 0;
}
@font-face {
  font-family: "Untitled-Sans-Regular";
  src: url("untitled-sans-web-regular.084a4ed9.eot");
  src: url("untitled-sans-web-regular.084a4ed9.eot") format("embedded-opentype"), url("untitled-sans-web-regular.a6f26b83.woff2") format("woff2"), url("untitled-sans-web-regular.564f92ac.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Untitled-Sans-Medium";
  src: url("untitled-sans-web-medium.bdb1afca.eot");
  src: url("untitled-sans-web-medium.bdb1afca.eot") format("embedded-opentype"), url("untitled-sans-web-medium.2e9bafbb.woff2") format("woff2"), url("untitled-sans-web-medium.62a4db03.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Untitled-Sans-Bold";
  src: url("untitled-sans-web-bold.37f17ff1.eot");
  src: url("untitled-sans-web-bold.37f17ff1.eot") format("embedded-opentype"), url("untitled-sans-web-bold.0620c268.woff2") format("woff2"), url("untitled-sans-web-bold.05ee7a0a.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
:root {
  --easing: cubic-bezier(0.5, 0.7, 0.4, 1);
  --easing-overshoot: cubic-bezier(0.365, 0.050, 0.780, 1.650);
  --bg-color: #14100c;
  --card-color: #0e0b09;
  --alt-color: #e45151;
}
::-moz-selection {
  color: var(--bg-color);
  background: var(--alt-color);
}
::selection {
  color: var(--bg-color);
  background: var(--alt-color);
}
body {
  font-family: "Untitled-Sans-Regular", sans-serif;
  line-height: 1.2;
  color: var(--alt-color);
  background-image: url("bg-intro-2-blend-hard-light.b0d6b88e.jpg");
  background-blend-mode: hard-light;
  background-attachment: fixed;
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
  background-color: var(--bg-color);
  transition: 300ms background-color var(--easing);
  z-index: -3;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
}
@media only screen and (max-width: 991.98px) {
  body {
    background-image: none;
  }
}
.background-color {
  background-color: var(--bg-color);
  z-index: -1;
  height: 100vh;
  width: 100%;
  position: fixed;
}
.logo {
  visibility: hidden;
  position: fixed;
  width: 52px;
  height: 52px;
  top: 16px;
  left: 16px;
  border: 0px solid var(--alt-color);
  border-radius: 6px;
  fill: var(--alt-color);
  cursor: pointer;
  z-index: 10;
  transition: 2s fill var(--easing), 300ms scale;
}
.logo:focus {
  opacity: 0.6;
  outline: 0;
}
.logo:active {
  background-color: var(--alt-color);
  color: var(--bg-color);
  outline: 0;
}
@media only screen and (max-width: 991.98px) {
  .logo {
    background-color: var(--bg-color);
  }
}
@media only screen and (max-width: 767.98px) {
  .logo {
    left: 8px;
    top: 8px;
    width: 32px;
    height: 32px;
  }
}
.logo .pppp-logo {
  width: 52px;
  transition: 300ms transform var(--easing);
}
@media only screen and (max-width: 767.98px) {
  .logo .pppp-logo {
    width: 32px;
  }
}
.logo .pppp-logo:focus {
  transform: scale(2);
}
.wrapper {
  padding: 28px 0px 16px 0px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1680px;
}
@media only screen and (max-width: 767.98px) {
  .wrapper {
    padding: 14px 0px 16px 48px;
  }
}
a.further {
  border-bottom: 2px solid var(--alt-color);
  border-radius: 2px;
  padding: 2px;
  margin-top: 0.4rem;
  transition: 2s border-bottom var(--easing);
}
a.further:focus, a.further:hover {
  outline: 0;
  background-color: var(--alt-color);
  color: var(--bg-color);
}
a.further:active {
  opacity: 0.8;
}
header {
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 3;
  width: 100%;
  padding-top: 28px;
  padding-left: 84px;
}
@media only screen and (max-width: 991.98px) {
  header {
    background-color: var(--bg-color);
    justify-content: flex-start;
    margin-left: 0px;
    padding-top: 0px;
    height: 84px;
  }
}
@media only screen and (max-width: 767.98px) {
  header {
    padding-left: 48px;
    height: 48px;
  }
}
header h1 {
  font-size: 1.4rem;
  position: sticky;
  color: inherit;
  transition: 2s color var(--easing);
}
@media only screen and (max-width: 767.98px) {
  header h1 {
    font-size: 1rem;
  }
}
header .rotated {
  margin-right: 8px;
  transition: 2s color var(--easing);
  color: inherit;
  position: fixed;
  top: 50vh;
  left: -8.6rem;
  transform: rotate(270deg);
}
@media only screen and (max-width: 767.98px) {
  header .rotated {
    display: block;
    left: -160px;
  }
}
footer .colored-songs-container {
  visibility: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--alt-color);
  width: 100%;
  padding: 0px 16px;
  font-size: 1rem;
  color: var(--bg-color);
  transition: background-color 2s var(--easing);
  z-index: 3;
}
@media only screen and (max-width: 991.98px) {
  footer .colored-songs-container {
    display: none;
  }
}
footer .colored-songs-container #color-bar {
  background-color: var(--bg-color);
  height: 4px;
  width: 44px;
  border-radius: 2px;
  transform: translate(0px);
  z-index: 2;
  position: fixed;
  bottom: 0px;
  left: 10px;
  transition: left 200ms ease-in-out, background-color 300ms var(--easing);
}
@media only screen and (max-width: 767.98px) {
  footer .colored-songs-container #color-bar {
    display: none;
  }
}
.description {
  padding: 12px;
}
@media only screen and (max-width: 1199.98px) {
  .description {
    padding: 16px;
  }
}
.colored-songs {
  font-family: "Untitled-Sans-Medium";
  margin-left: 4px;
  padding: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 2px;
}
@media only screen and (max-width: 1199.98px) {
  .colored-songs {
    padding: 8px;
  }
}
.colored-songs:focus, .colored-songs:hover {
  outline: 0px;
  opacity: 0.4;
}
.colored-songs:active {
  background-color: var(--bg-color);
  color: var(--alt-color);
}
.colored-songs-menu {
  display: none;
  background-color: transparent;
  padding-right: 30px;
  padding-bottom: 10rem;
  visibility: hidden;
  position: fixed;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: -100vh;
  width: 100vw;
  height: 100vh;
  font-size: 1rem;
  text-align: center;
  border-radius: 2px;
  background-color: var(--bg-color);
  color: var(--alt-color);
  z-index: 4;
  opacity: 0.3;
  transition: 600ms margin-top var(--easing), 600ms opacity, 300ms color var(--easing), 600ms background-color var(--easing);
}
.colored-songs-menu p {
  background-color: var(--bg-color);
  position: absolute;
  margin-bottom: 2.6rem;
  font-size: 1rem;
  margin-right: 8px;
  top: 50vh;
  left: -118px;
  padding: 2px 8px;
  border-radius: 16px;
  transform: rotate(270deg);
  transition: 2s color var(--easing);
}
.colored-songs-menu a {
  padding: 12px;
  font-family: "Untitled-Sans-Medium";
  margin-bottom: 0.4rem;
  border: 2px solid var(--bg-color);
  border-radius: 2px;
  transition: 2s border-color var(--easing), 2s color var(--easing);
}
.colored-songs-menu a:focus, .colored-songs-menu a:hover {
  outline: 0px;
  border: 2px solid var(--alt-color);
}
.colored-songs-menu a:active {
  background-color: var(--alt-color);
  color: var(--bg-color);
}
@media only screen and (max-width: 991.98px) {
  .colored-songs-menu a {
    font-size: 1.4rem;
    font-family: "Untitled-Sans-Medium";
  }
}
@media only screen and (max-width: 991.98px) {
  .colored-songs-menu {
    display: flex;
    margin-left: 84px;
    padding-right: 114px;
  }
}
@media only screen and (max-width: 767.98px) {
  .colored-songs-menu {
    margin-left: 48px;
    padding-right: 78px;
  }
}
.opened {
  visibility: hidden;
  background-color: var(--bg-color);
  margin-top: 0vh;
  opacity: 1;
}
#intro {
  visibility: hidden;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: inherit;
  transition: 2s color var(--easing);
}
@media only screen and (max-width: 767.98px) {
  #intro {
    flex-direction: row;
    padding-left: unset;
    padding-right: 48px;
    justify-content: space-between;
  }
}
#intro h2 {
  font-size: 3rem;
  padding-bottom: 1.2rem;
}
@media only screen and (max-width: 767.98px) {
  #intro h2 {
    width: 40%;
    font-size: 1.8rem;
    padding-bottom: 0px;
    margin-bottom: 128px;
    text-align: right;
  }
}
#intro p {
  font-size: 1.8rem;
  margin-bottom: 0.6rem;
}
@media only screen and (max-width: 767.98px) {
  #intro p {
    padding-left: 8px;
    width: 32%;
    margin-bottom: 128px;
  }
}
#intro .scroll-arrow {
  font-size: 4rem;
  transform: rotate(90deg);
  position: fixed;
  left: 50vw;
  bottom: 86px;
  color: inherit;
  transition: 2s color var(--easing);
}
@media only screen and (max-width: 991.98px) {
  #intro .scroll-arrow {
    position: absolute;
  }
}
#about-me {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: inherit;
  transition: 2s color var(--easing);
}
@media only screen and (max-width: 767.98px) {
  #about-me {
    padding-left: unset;
    padding-right: 16px;
  }
}
#about-me p {
  font-size: 2.5rem;
  width: 76%;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 767.98px) {
  #about-me p {
    width: unset;
    font-size: 1.5rem;
    padding-left: 16px;
    padding-right: 16px;
  }
}
#work {
  min-height: 100vh;
  color: inherit;
  transition: 2s color var(--easing);
  padding-left: 86px;
  padding-right: 86px;
}
@media only screen and (max-width: 991.98px) {
  #work {
    padding-right: 16px;
  }
}
@media only screen and (max-width: 767.98px) {
  #work {
    padding-left: 0px;
    padding-right: 36px;
    width: 100%;
  }
}
#work .work-item {
  border-radius: 4px;
  padding: 4rem;
  background: linear-gradient(to top, var(--bg-color), var(--card-color));
  margin-bottom: 10rem;
  align-items: center;
}
@media only screen and (max-width: 991.98px) {
  #work .work-item {
    padding: 2rem;
  }
}
@media only screen and (max-width: 767.98px) {
  #work .work-item {
    padding: 1rem;
  }
}
#work .work-item h3 {
  min-width: 40%;
  padding: 4px 4px 4px 0px;
}
@media only screen and (max-width: 767.98px) {
  #work .work-item h3 {
    text-align: left;
    font-size: 1.6rem;
  }
}
#work .work-item p {
  margin-bottom: 3rem;
}
@media only screen and (max-width: 767.98px) {
  #work .work-item p {
    margin-bottom: 1rem;
  }
}
#work .work-item .work-item-top {
  width: 100%;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 767.98px) {
  #work .work-item .work-item-top {
    margin-bottom: 1.4rem;
  }
}
#work .work-item .work-item-main {
  display: flex;
  flex-direction: row;
  padding-top: 64px;
}
@media only screen and (max-width: 767.98px) {
  #work .work-item .work-item-main {
    flex-direction: column-reverse;
    padding-top: 0px;
  }
}
#work .work-item .work-item-main .work-item-left {
  width: 40%;
  margin-right: 44px;
  overflow: hidden;
}
@media only screen and (max-width: 991.98px) {
  #work .work-item .work-item-main .work-item-left {
    width: 45%;
  }
}
@media only screen and (max-width: 767.98px) {
  #work .work-item .work-item-main .work-item-left {
    width: 100%;
    padding-top: 2rem;
  }
}
#work .work-item .work-item-main .work-item-left h4 {
  font-family: "Untitled-Sans-Medium";
}
#work .work-item .work-item-main .work-item-left p {
  margin-bottom: 1rem;
}
#work .work-item .work-item-main .work-item-right {
  width: 60%;
  font-size: 1.4rem;
}
@media only screen and (max-width: 991.98px) {
  #work .work-item .work-item-main .work-item-right {
    width: 55%;
  }
}
@media only screen and (max-width: 767.98px) {
  #work .work-item .work-item-main .work-item-right {
    width: 100%;
    font-size: 1rem;
  }
}
#work .work-item .work-item-main .work-item-right p {
  padding: 4px 4px 4px 0px;
}
#work .work-item .work-item-main .work-item-right img {
  width: 100%;
  height: auto;
  max-width: 86%;
  box-shadow: 7px 7px 18px 0px #0c0908;
}
#work .work-item .work-item-footer {
  width: 100%;
}
#services {
  min-height: 100vh;
  color: inherit;
  transition: 2s color var(--easing);
}
@media only screen and (max-width: 575.98px) {
  #services {
    flex-direction: column;
    align-items: stretch;
  }
}
#services div {
  display: flex;
  flex-direction: row;
  text-align: center;
  padding-left: 86px;
  padding-right: 86px;
}
@media only screen and (max-width: 991.98px) {
  #services div {
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 96px;
    padding-right: 96px;
  }
}
@media only screen and (max-width: 767.98px) {
  #services div {
    padding-left: 0px;
    padding-right: 48px;
  }
}
#services .service-item {
  padding: 2rem;
  border-radius: 4px;
  margin: 1.4rem;
  text-align: left;
  flex-basis: 100%;
  min-width: 0;
}
#services .service-item .service-number {
  font-family: "Untitled-Sans-Regular";
  font-size: 2rem;
  padding-left: 0px;
  padding-right: 0px;
}
@media only screen and (max-width: 991.98px) {
  #services .service-item .service-number {
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 767.98px) {
  #services .service-item .service-number {
    text-align: left;
  }
}
#services .service-item h3 {
  margin-bottom: 2rem;
}
@media only screen and (max-width: 991.98px) {
  #services .service-item h3 {
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 767.98px) {
  #services .service-item h3 {
    text-align: left;
  }
}
@media only screen and (max-width: 575.98px) {
  #services .service-item {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 767.98px) {
  #services .service-item {
    padding: 16px;
    text-align: left;
  }
}
#contact {
  padding-left: 86px;
  padding-right: 86px;
  padding-bottom: 10rem;
  display: flex;
  min-height: 100vh;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: inherit;
  transition: 2s color var(--easing);
}
@media only screen and (max-width: 767.98px) {
  #contact {
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 0px;
    padding-right: 48px;
  }
}
#contact p {
  font-size: 2rem;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 991.98px) {
  #contact p {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 991.98px) {
  #contact .contact-left {
    margin-top: 10rem;
  }
}
#contact .contact-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10vh;
}
#contact .contact-right .contact-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media only screen and (max-width: 991.98px) {
  #contact .contact-right .contact-buttons {
    padding-left: 32px;
    flex-direction: column;
    font-size: 1.4rem;
  }
}
#contact .contact-right .contact-buttons a.get-in-touch {
  display: block;
  padding: 12px;
  font-size: 2rem;
  border-radius: 2px;
  margin-right: 28px;
  margin-bottom: 0.4rem;
  border: 2px solid transparent;
  color: var(--var-color);
  font-family: "Untitled-Sans-Medium";
  transition: background-color 300ms ease-out;
}
@media only screen and (max-width: 767.98px) {
  #contact .contact-right .contact-buttons a.get-in-touch {
    font-size: 1.4rem;
  }
}
#contact .contact-right .contact-buttons a.get-in-touch:focus, #contact .contact-right .contact-buttons a.get-in-touch:hover {
  outline: 0px;
  border: 2px solid var(--alt-color);
}
#contact .contact-right .contact-buttons a.get-in-touch:active {
  background-color: var(--alt-color);
  color: var(--bg-color);
}
#copyright {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
  transition: 2s color var(--easing);
}
#copyright .small {
  font-size: 0.8rem;
}
@media only screen and (max-width: 767.98px) {
  #copyright {
    padding-left: 48px;
    padding-right: 98px;
    text-align: center;
  }
}
h2, h3 {
  font-family: "Untitled-Sans-Medium", sans-serif;
  font-size: 2rem;
}
h4 {
  margin-bottom: 0.2rem;
}
.pre-headline {
  margin-bottom: 0.2rem;
}
@media only screen and (max-width: 767.98px) {
  .pre-headline {
    text-align: left;
    margin-bottom: 0.4rem;
  }
}
.section-headline {
  margin-top: 10rem;
  margin-bottom: 10rem;
  margin-left: 86px;
  padding-right: 86px;
  text-align: right;
  font-size: 1.4rem;
  font-family: "Untitled-Sans-Regular";
  transition: 2s color var(--easing);
}
@media only screen and (max-width: 991.98px) {
  .section-headline {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: var(--bg-color);
    font-size: 1.4rem;
    padding-right: 32px;
    margin-bottom: 4rem;
    height: 84px;
  }
}
@media only screen and (max-width: 767.98px) {
  .section-headline {
    font-size: 1rem;
    margin-left: 0px;
    padding-right: 48px;
    height: 48px;
  }
}
.bold {
  font-family: "Untitled-Sans-Bold";
}
.medium {
  font-family: "Untitled-Sans-Medium";
}
.logo-privacy {
  visibility: visible;
}
.privacy {
  padding: 2rem 14rem 4rem 14rem;
}
@media only screen and (max-width: 767.98px) {
  .privacy {
    padding: 4rem 48px;
  }
}
.privacy h1 {
  font-size: 2rem;
  font-family: "Untitled-Sans-Medium";
  margin-bottom: 1rem;
}
.privacy h2 {
  margin-top: 2.6rem;
  font-size: 1.4rem;
  margin-bottom: 0.4rem;
}
.privacy h3 {
  margin-top: 1rem;
  font-family: "Untitled-Sans-Bold";
  font-size: 1rem;
  margin-bottom: 0.4rem;
}
.privacy p {
  margin-bottom: 2rem;
}
/*# sourceMappingURL=privacy-policy.4e11d7d5.css.map */
