@mixin small {
    @media only screen and (max-width: 575.98px) { 
        @content;
    }
};

// Small devices (landscape phones, less than 768px)
@mixin medium {
    @media only screen and (max-width: 767.98px) { 
        @content;
    }
};

// Medium devices (tablets, less than 992px)
@mixin large {
    @media only screen and (max-width: 991.98px) { 
        @content; 
    }
};

// Large devices (desktops, less than 1200px)
@mixin very-large {
    @media only screen and (max-width: 1199.98px) { 
        @content; 
    }
};

@mixin main-padding {
    padding-left: 86px;
    padding-right: 86px;
}